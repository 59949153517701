import { Col, Row } from "antd"
import { NextSeo } from "next-seo"

function Index() {
  return (
    <>
      <NextSeo
        title="The page you are looking for could not be found"
        description="Use the menu above to go back to the Birdflocks website"
        openGraph={{
          title: "The page you are looking for could not be found",
          site_name: "The page you are looking for could not be found",
          description:
            "Use the menu above to go back to the Birdflocks website",
        }}
      />
      <Row
        className="mh-screen text-center"
        align="middle"
        justify="center"
        style={{ minHeight: "calc(100vh - 56px - 200px)" }}
      >
        <Col xs={{ span: 22 }} lg={{ span: 20 }} xl={{ span: 8 }}>
          <img className="w-[300px] mx-auto" src="/assets/onBoarding/404.png" />
          <div className="w-full my-10 flex-shrink-0">
            <div className="f-35 font-bold text-primary">
              The page you are looking for could not be found.
            </div>
            <div className="f-18 mt-5">
              Use the menu above to go back to the Birdflocks website.
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Index
